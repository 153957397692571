import axios from "axios";
import { DELETE, GET, PATCH, POST, PUT, API_KEY, OPTION, BASE_URL, USER } from "../app.config";

const baseUrl = BASE_URL;
/**
 * [request description]
 * @param  {[string]} url       URL of the API which needs to be consumed by client
 * @param  {[string]} method    Method type of the API call currently GET,POST,PUT,DELETE is supported in order suport to more methods add method name to the array -> allowedMethodTypes
 * @param  {[JSON]} payload     Payload to be provided to server for sending data
 * @param  {[string]} headers   Request Headers required by the server side to process the API call
 * @return {[JSON]}             Response provided by the server side code
 */
// `Token ${JSON.parse(localStorage.getItem(USER))?.token}`
const request = async (url, method, token, data = {}, params = {}) => {
  try {
    const allowedMethodTypes = [GET, POST, PUT, PATCH, DELETE, OPTION];

    if (allowedMethodTypes.indexOf(method.toLowerCase()) < 0) {
      throw new Error(`Invalid method type please provide one of these methods... \n ${allowedMethodTypes}`);
    }
    const headers = {
      "API-KEY": API_KEY,
      ...(token && { Authorization: `Token ${JSON.parse(localStorage.getItem(USER))?.token}` }),
    };

    const result = await axios({ method, url, baseURL: baseUrl, params, data, headers });
    return result;
  } catch (error) {
    if (error.response.data.code === -1) {
      localStorage.removeItem(USER);
    } else {
      throw new Error(error);
    }
  }
};

export default request;
