const TermsPage = () => {
  return (
    <section className="block mt-5">
      <div className="container-fluid">
        <div className="row about-sec">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="about-us wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
              <h2>Term & Conditions</h2>
              <p>These terms of use govern your access and use of our AI music creation app.</p>

              <h3>1. Eligibility</h3>

              <ul>
                <li><p>You must be at least 13 years old to use the app.</p></li>
                <li><p>You must comply with all applicable laws and regulations.</p></li>
              </ul>

              <h3>2. Content Ownership</h3>

              <ul>
                <li><p>You retain ownership of the music you create.</p></li>
                <li><p>By sharing your music, you grant us a non-exclusive license to display and distribute it within the app.</p></li>
              </ul>

              <h3>3. User Conduct</h3>

              <ul>
                <li><p>You agree to use the app in a respectful and lawful manner.</p></li>
                <li><p>You are prohibited from uploading or sharing content that is:</p>
                  <ul>
                    <li><p>Infringing on copyrights</p></li>
                    <li><p>Harassing or discriminatory</p></li>
                    <li><p>Illegal or harmful</p></li>
                  </ul>
                </li>
              </ul>

              <h3>4. Disclaimer of Warranties</h3>

              <ul>
                <li><p>The app is provided "as is" and without warranties of any kind.</p></li>
                <li><p>We are not responsible for any loss or damage arising from your use of the app.</p></li>
              </ul>

              <h3>5. Limitation of Liability</h3>

              <ul>
                <li><p>Our liability for any damages is limited to the maximum extent permitted by law.</p></li>
              </ul>

              <h3>6. Termination</h3>

              <ul>
                <li><p>We may terminate your account for any reason at any time.</p></li>
              </ul>


            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsPage;
