const PrivacyPage = () => {
  return (
    <section className="block mt-5">
      <div className="container-fluid">
        <div className="row about-sec">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="about-us wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
              <h2>Privacy Policy</h2>
              <p>This privacy policy describes how we collect, use, and share your information when you use our AI music creation app.</p>

              <h3>1. Information We Collect</h3>

              <ul>
                <li><p>User account information (email, username, profile picture)</p></li>
                <li><p>Music creation preferences (instruments, genre, tempo)</p></li>
                <li><p>Shared songs and associated metadata (excluding personally identifiable information)</p></li>
                <li><p>Aggregated usage data (e.g., number of users, popular features)</p></li>
                <li><p>Device information (operating system version, device type, screen resolution)</p></li>
              </ul>

              <h3>2. How We Use Your Information</h3>

              <ul>
                <li><p>To personalize your music creation experience</p></li>
                <li><p>To improve the app and develop new features</p></li>
                <li><p>To analyze usage trends and optimize app performance</p></li>
                <li><p>To comply with legal requirements and safety obligations</p></li>
              </ul>

              <h3>3. Sharing Your Information</h3>

              <ul>
                <li><p>We will not share your personal information with third parties without your consent, except:</p></li>
                <ul>
                  <li><p>As required by law</p></li>
                  <li><p>With service providers who help us operate the app</p></li>
                  <li><p>In the event of a merger or acquisition</p></li>
                </ul>
              </ul>

              <h3>4. Data Security</h3>

              <ul>
                <li><p>We implement industry-standard security measures to protect your information.</p></li>
                <li><p>However, no method of transmission or storage is completely secure.</p></li>
              </ul>

              <h3>5. Your Choices</h3>

              <ul>
                <li><p>You can access, update, or delete your account information at any time.</p></li>
                <li><p>You can opt out of promotional emails by following the unsubscribe link.</p></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPage;
