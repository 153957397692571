import { Link, useLocation, useNavigate } from "react-router-dom";
import { USER } from "../../app.config";
import * as API from "../../utils/api.service";

const Menus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/")[1];

  const USER_TOKEN = JSON.parse(localStorage.getItem(USER))?.token;

  const handleLogout = async () => {
    try {
      const { code, message } = await API.logout();
      if (code === "1") {
        localStorage.removeItem(USER);
        navigate("/login");
      } else alert(message);
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <ul>
      <li className={path === "" ? "active" : ""}>
        <Link to="/">Home</Link>
      </li>
      <li className={path === "music-generator" ? "active" : ""}>
        <Link to="/music-generator">AI Music Generator</Link>
      </li>
      <li className={path === "music-assistant" ? "active" : ""}>
        <Link to="/music-assistant">AI Music Assistant</Link>
      </li>
      <li className={path === "about" ? "active" : ""}>
        <Link to="/about">About</Link>
      </li>
      <li className={path === "contact" ? "active" : ""}>
        <Link to="/contact">Contact</Link>
      </li>
      <li>
        {USER_TOKEN ? (
          <button onClick={handleLogout} className="btn-outline-white btn-small wow slideInUp">
            Logout
          </button>
        ) : (
          <button onClick={() => navigate("/login")} className="btn-outline-white btn-small wow slideInUp">
            Sign In
          </button>
        )}
      </li>
    </ul>
  );
};

export default Menus;
