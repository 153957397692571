const FAQPage = () => {
  return (
    <section className="block mt-5">
      <div className="container-fluid">
        <div className="row about-sec">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="about-us wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
              <h2>FAQ's</h2>
              <h3>How does the AI generate music?</h3>
              <p>Our AI model analyzes your chosen instruments, genre, and tempo to create unique musical sequences based on complex algorithms.</p>

              <h3>Can I download my created music?</h3>
              <p>Yes, you can download your songs in various formats for personal use.</p>

              <h3>Can I use my created music commercially?</h3>
              <p>Please refer to our licensing terms for detailed information on commercial use.</p>

              <h3>How do I report content that violates the terms?</h3>
              <p>You can report inappropriate content using the "report" button on the platform.</p>

              <h3>What happens to my data if I delete my account?</h3>
              <p>We will permanently remove your personal information upon account deletion, except for anonymized usage data for analytics purposes.</p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQPage;
