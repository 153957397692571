import request from "./axios.service";
import { GET, POST, PUT, PATCH, DELETE, BASE_URL, ADMIN, OPTION, COUNT_PER_PAGE, USER, MUSIC } from "../app.config";

// <-----------------auth------------------>
export const login = (body) => {
  return request(`/${USER}/login`, POST, false, body)
    .then((response) => {
      return JSON.parse(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const signUp = (body) => {
  return request(`/${USER}/signup`, POST, false, body)
    .then((response) => {
      return JSON.parse(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const logout = (body) => {
  return request(`/${USER}/logout`, POST, true, body)
    .then((response) => {
      return JSON.parse(response.data);
    })
    .catch((error) => {
      throw error;
    });
};

export const generateMusic = (body) => {
  return request(`/${MUSIC}/generate_music`, POST, true, body)
    .then((response) => {
      return JSON.parse(response.data);
    })
    .catch((error) => {
      throw error;
    });
};
