import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthCommon from "../AuthCommon";
import * as API from "../../utils/api.service";
import { USER } from "../../app.config";
import { AppContext } from "../../appContext";

const SignUp = () => {
  const [fields, setFields] = useState({ fName: "", lName: "", email: "", password: "", confirmPassword: "" });
  const { setLoader } = useContext(AppContext);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = async (e) => {
    try {
      setLoader(true);
      e.preventDefault();
      const { fName, lName, email, password, confirmPassword } = fields;
      if (password !== confirmPassword) {
        alert("Confirm password should be same as password");
        setLoader(false);
        return;
      }
      const { data, message, code } = await API.signUp({
        login_type: 0,
        f_name: fName,
        l_name: lName,
        email,
        password,
      });
      if (code == 1) {
        localStorage.setItem(USER, JSON.stringify(data));
        navigate("/");
      } else {
        alert(message);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      alert(err.message);
    }
  };

  return (
    <div className="main">
      <section className="login-main">
        <AuthCommon>
          <Link to="/login">Login</Link>
        </AuthCommon>
        <div className="section-right">
          <div className="login-form">
            <div className="login-title">
              <h3>Let’s Get Started</h3>
              <p>Let us help you save energy &amp; money</p>
            </div>

            <div className="login-tabs">
              <form onSubmit={handleSubmit} className="common_form">
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="fName"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter first name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    onChange={handleChange}
                    name="lName"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter last name"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    onChange={handleChange}
                    name="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    required
                  />
                </div>
                <div className="mb-3 form-group">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Enter Password
                  </label>
                  <input
                    type="password"
                    onChange={handleChange}
                    name="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="********"
                    required
                  />
                  {/* <!-- <a href="#" className="icon-hide-show"><img src="assets/images/icon-eyes.svg" className="img-fluid"></a> --> */}
                </div>
                <div className="mb-3 form-group">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    onChange={handleChange}
                    name="confirmPassword"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="********"
                    required
                  />
                  {/* <!-- <a href="#" className="icon-hide-show"><img src="assets/images/icon-eyes.svg" className="img-fluid"></a> --> */}
                </div>
                <button type="submit" className="btn-white mt-3 mb-3 d-block w-100 wow slideInUp">
                  Sign up
                </button>
                {/* <!-- <a href="index.html" className="btn-theme me-3 w-100 mt-3 mb-3 d-block">Login</a> -->
                         <!-- <a href="forgot-password.html" className="forgot-password">Forgot Password?</a> --> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
