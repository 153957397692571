import { Link, useNavigate } from "react-router-dom";
import { USER } from "../../app.config";
import genre1 from "../../assets/images/choose-1.png"
import genre2 from "../../assets/images/choose-2.png"
import genre3 from "../../assets/images/choose-3.png"
import genre4 from "../../assets/images/choose-4.png"
import genre5 from "../../assets/images/choose-5.png"
import genre6 from "../../assets/images/choose-6.png"
import musicGenre1 from "../../assets/images/music-genres-2.jpg"
import musicGenre2 from "../../assets/images/music-genres-3.jpg"
import musicGenre3 from "../../assets/images/music-genres-4.jpg"
import image1 from "../../assets/images/img4.png"

const Home = () => {
  const USER_TOKEN = JSON.parse(localStorage.getItem(USER))?.token;
  const navigate = useNavigate();

  return (
    <>
      {/* <section id="slider" className="slider ">
        <div className="rev_slider_wrapper">
          <div id="slider2" className="rev_slider" data-version="5.0">
            <ul>
              <li
                data-index="rs-6"
                data-transition="fade"
                data-slotamount="default"
                data-hideafterloop="0"
                data-hideslideonmobile="off"
                data-easein="default"
                data-easeout="default"
                data-masterspeed="300"
                data-thumb="assets/images/resources/banner/sound-engineer/banner5.jpg"
                data-rotate="0"
                data-saveperformance="off"
                data-title="Slide"
                data-param1=""
                data-param2=""
                data-param3=""
                data-param4=""
                data-param5=""
                data-param6=""
                data-param7=""
                data-param8=""
                data-param9=""
                data-param10=""
                data-description=""
              >
                <img
                  src="assets/images/resources/banner/sound-engineer/banner5.jpg"
                  alt="a"
                  title="main-slider-backround-1"
                  width="1920"
                  height="1200"
                  data-bgposition="center center"
                  data-duration="13000"
                  data-ease="Power0.easeInOut"
                  data-scalestart="100"
                  data-scaleend="110"
                  data-rotatestart="0"
                  data-rotateend="0"
                  data-blurstart="0"
                  data-blurend="0"
                  data-offsetstart="0 0"
                  data-offsetend="0 0"
                  data-bgparallax="7"
                  className="rev-slidebg"
                  data-no-retina
                />
                <div
                  className="tp-caption tp-resizeme"
                  id="slide-6-layer-1"
                  data-x="['left','left','left','left']"
                  data-hoffset="['-89','-7','15','0']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['-110','-73','-111','-131']"
                  data-fontsize="['33','33','33','33']"
                  data-lineheight="['45','50','36','30']"
                  data-letterspacing="['-9','-8','-7','-4']"
                  data-width="['804','627','517','297']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"delay":500,"speed":300,"frame":"0","from":"x:350px;opacity:0;","to":"o:1;","ease":"Power1.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                  data-textAlign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    zIndex: 5,
                    fontSize: "42px",
                    lineHeight: "92.71px",
                    fontWeight: 700,
                    fontFamily: "'Unbounded', cursive",
                    color: "#ffffff",
                    letterSpacing: "1px",
                    textTransform: "capitalize",
                  }}
                >
                  PUSH YOUR IMAGINATION TO <br /> THE LIMIT AND CROSS IT.
                </div>
                <div
                  className="tp-caption   tp-resizeme"
                  id="slide-6-layer-2"
                  data-x="['left','left','left','left']"
                  data-hoffset="['-81','0','15','3']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['59','59','59','10']"
                  data-fontsize="['18','18','18','14']"
                  data-lineheight="['22','22','22','20']"
                  data-width="['610','610','515','292']"
                  data-height="none"
                  data-whitespace="normal"
                  data-type="text"
                  data-responsive_offset="on"
                  data-frames='[{"delay":600,"speed":300,"frame":"0","from":"x:300px;opacity:0;","to":"o:1;","ease":"Power1.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                  data-textAlign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[0,0,0,0]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[0,0,0,0]"
                  style={{
                    color: "#ffffff",
                    fontSize: 18,
                    lineHeight: 300,
                    fontWeight: 400,
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Thanks to Lexus, Rolling Out’s New AI Music Lab redefines music production and enables everyone to
                  experience the magic of generative music creation. Try our new AI Music Creator and Music Assistant to
                  create samples and tracks that expand beyond today's musical genres.
                </div>
                <a
                  className="tp-caption btn-lnk tp-resizeme"
                  href=""
                  target="_blank"
                  id="slide-6-layer-3"
                  data-x="['left','left','left','left']"
                  data-hoffset="['-80','0','15','3']"
                  data-y="['middle','middle','middle','middle']"
                  data-voffset="['183','183','183','140']"
                  data-responsive_offset="on"
                  data-frames='[{"delay":750,"speed":300,"frame":"0","from":"x:250px;opacity:0;","to":"o:1;","ease":"Power1.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]'
                  data-textAlign="['inherit','inherit','inherit','inherit']"
                  data-paddingtop="[0,0,0,0]"
                  data-paddingright="[30,30,30,30]"
                  data-paddingbottom="[0,0,0,0]"
                  data-paddingleft="[30,30,30,30]"
                  style={{ zIndex: 7, textDecoration: "none" }}
                >
                  CREATE FOR FREE NOW
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section> */}
      <section className="main-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="banner-title" id="slide-6-layer-1">
                <h2 className="wow fadeInRight">
                  PUSH YOUR IMAGINATION TO <br /> THE LIMIT AND CROSS IT.
                </h2>

                <p className="wow fadeInRight">
                  Thanks to Lexus, Rolling Out’s New AI Music Lab redefines music production and enables everyone to
                  experience the magic of generative music creation. Try our new AI Music Creator and Music Assistant to
                  create samples and tracks that expand beyond today's musical genres.
                </p>
                {!USER_TOKEN ? (
                  <Link className="btn btn-theme-banner wow fadeInRight" to="/signup">
                    Sign-Up and Create
                  </Link>
                ) : (
                  <Link className="btn btn-theme-banner wow fadeInRight" to="/music-generator">
                    Generate Music
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="block playlist-sec choose-your-genre">
        <div className="container-fluid text-center">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="signup-create title-sec wow fadeInUp">
                <h3>
                  Sign up to join Rolling Out X Lexus and experience their new collaboration that pushes the boundaries
                  of co-creation, culture, and technology.
                </h3>
              </div>
              <Link className="btn-red mb-3  wow slideInUp" to="/signup">
                SIGN-UP TO CREATE
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="block pt-0">
        <div className="container-fluid">
          <div className="row about-sec">
            <div className="col-xl-6 col-lg-12 col-md-12 col-12">
              <div className="about-us wow fadeInLeft">
                <h2>AI Beat Maker</h2>
                <p>
                  The Rolling Out AI Music Lab was trained by analyzing rhythm, melody, and harmony patterns in existing
                  music compositions across every genre of music over the last fifty years. After you enter your prompt
                  -- it’s fed through our proprietary algorithm and combined with our massive music database to create a
                  unique new track just for you.
                </p>
                <p>
                  In addition to quickly generating new music using your natural language prompts, our AI Music
                  Assistant is here to help you customize and create custom studio-quality tracks by adding instruments,
                  discovering new chord progressions, and sampling new beats and loops. You now have a personal music
                  assistant thanks to Rolling Out’s new AI Music Lab.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-12">
              <div className="about-image wow fadeInRight">
                <img src={image1} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="block">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-10 col-12">
              <div className="title-sec text-center wow fadeInUp">
                <h2>Experience the Rolling out AI Music Lab</h2>
              </div>
            </div>
          </div>
          <div className="row about-sec bootm-space">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="about-us wow fadeInLeft">
                <h2 className="text-number">
                  <svg viewBox="0 0 1100 120">
                    <text x="2px" y="50%" alignmentBaseline="central" dominantBaseline="middle">
                      {" "}
                      Step #1{" "}
                    </text>
                  </svg>
                </h2>
                <h3>SELECT YOUR GENRE + GENERATE</h3>
                <p>
                  Craft precise, high-quality audio with advanced AI built by producers and audio engineers. Define your
                  beat. Choose a music genre and vibe, and Rolling Out AI Music Lab will generate a unique drum pattern,
                  melody, and bass to match your request.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="about-image wow fadeInRight">
                <img src={musicGenre1} alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="row about-sec bootm-space">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 order-1 order-sm-2">
              <div className="about-us wow fadeInRight">
                <h2 className="text-number">
                  <svg viewBox="0 0 1100 120">
                    <text x="2px" y="50%" alignmentBaseline="central" dominantBaseline="middle">
                      {" "}
                      Step #2{" "}
                    </text>
                  </svg>
                </h2>
                <h3>USE OUR AI MUSIC ASSISTANT + CUSTOMIZE</h3>
                <p>
                  Take your tracks to the next level. The sound, the style, and the beat are up to you. With the Rolling
                  Out AI Music Lab, you can instantly create a new loop or add drums, beats, and melodies to an existing
                  track or stem in real-time. You can adapt the tempo, switch the pitch, or design a sound that only you
                  can with more than 1,500 instruments available with just a click.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 order-2 order-sm-1">
              <div className="about-image wow fadeInLeft">
                <img src={musicGenre2} alt="" className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="row about-sec bootm-space">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="about-us wow fadeInLeft">
                <h2 className="text-number">
                  <svg viewBox="0 0 1100 120">
                    <text x="2px" y="50%" alignmentBaseline="central" dominantBaseline="middle">
                      {" "}
                      Step #3{" "}
                    </text>
                  </svg>
                </h2>
                <h3>PULL IT TOGETHER</h3>
                <p>
                  Every beat is unique because it’s yours. Now, select the structure for your beat in one click and our
                  AI will instantly map your loops and bring them together as a full beat. Now you have your final
                  track. Download it and share it with the world!
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="about-image wow fadeInRight">
                <img src={musicGenre3} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="block black-bg choose-your-genre">
        <div className="container-fluid mt-5">
          <div className="section-title-between choose-your-genre-title">
            <div className="title-sec wow fadeInUp">
              <h2>Choose Your Genre</h2>
              <p>Start your track. Pick your style.</p>
            </div>
            <a className="btn-red mobile-space-15 wow slideInUp" href="#" title="">
              AI Music Generator
            </a>
          </div>
          <form>
            <div className="row">
              <div
                onClick={() => navigate("/music-generator", { state: "Electronic" })}
                className="col-lg-4 col-md-6 col-sm-6"
              >
                <div className="your-genre-list form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value=""
                    id="flexCheckDefault1"
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault1">
                    <div className="playlist-col wow fadeInLeft">
                      <div className="playlist-thumb">
                        <img src={genre1} alt="" />
                      </div>
                      <div className="playlist-info">
                        <h3>Electronic Dance Music</h3>
                        <p>Light and happy with syncopated drums, airy pads, and strong emotions.</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                onClick={() => navigate("/music-generator", { state: "Jazz" })}
                className="col-lg-4 col-md-6 col-sm-6"
              >
                <div className="your-genre-list form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value=""
                    id="flexCheckDefault2"
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault2">
                    <div className="playlist-col wow fadeInLeft" data-wow-delay="200ms">
                      <div className="playlist-thumb">
                        <img src={genre2} alt="" />
                      </div>
                      <div className="playlist-info">
                        <h3>Jazz</h3>
                        <p>Grainy beats to melt into and change your focus.</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                onClick={() => navigate("/music-generator", { state: "Afrobeats" })}
                className="col-lg-4 col-md-6 col-sm-6"
              >
                <div className="your-genre-list form-check">
                  <label className="form-check-label" htmlFor="flexCheckDefault3">
                    <img
                      src="assets/images/resources/icons-check.svg"
                      className="check-icon position-absolute"
                      width="24"
                      alt=""
                    />
                    <div className="playlist-col wow fadeInLeft" data-wow-delay="400ms">
                      <div className="playlist-thumb">
                        <img src={genre3} alt="" />
                      </div>
                      <div className="playlist-info">
                        <h3>Afrobeats</h3>
                        <p>
                          Create your beat with drums, samples, loops, claps, bass, and heavy kicks to background your
                          rhymes.
                        </p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                onClick={() => navigate("/music-generator", { state: "Trap" })}
                className="col-lg-4 col-md-6 col-sm-6"
              >
                <div className="your-genre-list form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value=""
                    id="flexCheckDefault4"
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault4">
                    <div className="playlist-col wow fadeInLeft">
                      <div className="playlist-thumb">
                        <img src={genre4} alt="" />
                      </div>
                      <div className="playlist-info">
                        <h3>Trap</h3>
                        <p>Gritty, heavy sub-bass, super rhythmic snares, and a deep 800 kick.</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                onClick={() => navigate("/music-generator", { state: "Hip-Hop" })}
                className="col-lg-4 col-md-6 col-sm-6"
              >
                <div className="your-genre-list form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value=""
                    id="flexCheckDefault5"
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault5">
                    <div className="playlist-col wow fadeInRight" data-wow-delay="200ms">
                      <div className="playlist-thumb">
                        <img src={genre5} alt="" />
                      </div>
                      <div className="playlist-info">
                        <h3>Hip-Hop</h3>
                        <p>Head-bobbing beat with a deep bassline perfect for your verses.</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div
                onClick={() => navigate("/music-generator", { state: "Deep House" })}
                className="col-lg-4 col-md-6 col-sm-6"
              >
                <div className="your-genre-list form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value=""
                    id="flexCheckDefault6"
                  />
                  <label className="form-check-label" htmlFor="flexCheckDefault6">
                    <div className="playlist-col wow fadeInRight" data-wow-delay="400ms">
                      <div className="playlist-thumb">
                        <img src={genre6} alt="" />
                      </div>
                      <div className="playlist-info">
                        <h3>Deep House</h3>
                        <p>Mix up some Jazz, Funk, and a bit of soul and hit the clubs.</p>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section className="block playlist-sec choose-your-genre">
        <div className="container-fluid text-center">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-10 col-md-12 col-12">
              <div className="signup-create title-sec wow fadeInUp">
                <h3>
                  Help us shape the future of the Rolling Out AI Music Lab! We currently feature six music genres. What
                  we add next is up to you. Is there a genre you love that you'd like us to add? Share your suggestions
                  with us, and we'll add them to a future version of our APP. Your input matters – together, we can make
                  this the best AI Music Platform available.
                </h3>
              </div>
              <a className="btn-red mb-3 w-220px  wow slideInUp" href="mailto:suggestions@rollingoutaimusiclab.com" title="">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
