import { useMemo, useState, useContext } from "react";
import * as API from "../../utils/api.service";
import AudioPlayer from "../AudioPlayer";
import { AppContext } from "../../appContext";
import { Link, useLocation } from "react-router-dom";
import { GENRE_LIST, INSTRUMENT_LIST, USER } from "../../app.config";
import AudioList from "../AudioList";

const MusicGenerator = () => {
  const [search, setSearch] = useState({ genre: "", instrument: "" });
  const [playingAudioId, setPlayingAudioId] = useState("");
  const location = useLocation();
  const [fields, setFields] = useState({
    genre: location?.state || "",
    energy: "",
    instruments: [],
    duration: 0,
    bpm: 0,
  });
  const [audioList, setAudioList] = useState(null);
  const { setLoader } = useContext(AppContext);
  const USER_TOKEN = JSON.parse(localStorage.getItem(USER))?.token;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  // const handleChangeInstrument = (action, instrument) => {
  //   let tempInstruments = [...fields.instruments];
  //   if (action === "add") tempInstruments.push(instrument);
  //   else if (action === "remove") {
  //     const index = tempInstruments.indexOf(instrument);
  //     if (index !== -1) {
  //       tempInstruments.splice(index, 1);
  //     }
  //   }
  //   setFields({ ...fields, instruments: tempInstruments });
  // };

  const handleInstrument = (e) => {
    const { checked, value } = e.target;
    let tempInstruments = [...fields.instruments];
    if (checked) tempInstruments.push(value);
    else tempInstruments = tempInstruments.filter((item) => item !== value);
    setFields({ ...fields, instruments: tempInstruments });
  };

  const handleSubmit = async (e) => {
    try {
      setLoader(true);
      e.preventDefault();
      let body = { ...fields };
      body.instruments = body.instruments.join(",");
      const { data, message, code } = await API.generateMusic(body);
      if (code === "1") {
        setAudioList(data);
        // setFields({ genre: "", energy: "", instruments: [], duration: 0, bpm: 0 });

        // Scroll to the target div with smooth behavior
      const targetDiv = document.querySelector('#accordionPanelsStayOpenExample');
      console.log(targetDiv)
      targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      } else alert(message);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const showAudioList = useMemo(() => {
    // return audioList?.hits?.map((item, i) => {
    //   return (
    //     <AudioPlayer
    //       key={item.id}
    //       track={item}
    //       playingAudioId={playingAudioId}
    //       handlePlayigAudioId={(id) => setPlayingAudioId(id)}
    //       showOptions={true}
    //     />
    //   );
    // });
    const firstHit = audioList?.hits?.[0];

    if (firstHit) {
      return (
        <AudioPlayer
          key={firstHit.id}
          track={firstHit}
          playingAudioId={playingAudioId}
          handlePlayigAudioId={(id) => setPlayingAudioId(id)}
          showOptions={true}
        />
      );
    } else {
      return null; // Or return a placeholder if you prefer
    }

  }, [audioList, playingAudioId]);

  const showGenreList = GENRE_LIST.map((genre, i) => {
    if (genre.includes(search.genre))
      return (
        <div key={i} className="form-check">
          <div className="d-flex align-items-center">
            <input
              className="form-check-input"
              onChange={handleChange}
              type="radio"
              name="genre"
              value={genre}
              id="flexCheckDefault1"
              checked={fields.genre === genre}
            />
            <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault1">
              {genre}
            </label>
          </div>
        </div>
      );
  });

  const showInstrumentList = INSTRUMENT_LIST.map((instrument, i) => {
    if (instrument.includes(search.instrument))
      return (
        <div key={i} className="form-check">
          <div className="d-flex align-items-center">
            <input
              onChange={handleInstrument}
              checked={fields.instruments.some((item) => item === instrument)}
              className="form-check-input"
              type="checkbox"
              value={instrument}
              id="flexCheckDefault1"
            />
            <label className="form-check-label text-capitalize" htmlFor="flexCheckDefault1">
              {instrument}
            </label>
          </div>
        </div>
      );
  });

  // const showGenerateMusicResponse = (
  //   <>
  //     <div className="ganerator-song-title mb-5">
  //       <div className="song-images">
  //         <img src="assets/images/resources/ganerated-music.jpg" className="img-fluid" />
  //       </div>
  //       <div className="song-details">
  //         {fields.genre && <h3>{fields.genre}</h3>}
  //         <div className="selected-genr-list">
  //           {fields.genre && <span className="text-capitalize">{fields.genre}</span>}
  //           {fields?.instruments.length > 0 &&
  //             fields?.instruments.map((instrument, i) => {
  //               return (
  //                 <span className="text-capitalize" key={i}>
  //                   {instrument}
  //                 </span>
  //               );
  //             })}
  //           {fields.bpm && <span>BPM : {fields.bpm}</span>}{" "}
  //           {fields.duration && <span>Durations : {fields.duration}s</span>}
  //         </div>
  //       </div>
  //     </div>
  //     <div className="ganerated-music-body">{showAudioList}</div>
  //   </>
  // );

  return (
    <section className="block section-ai-music-genrator">
      <div className="container-fluid">
        <div className="row justify-content-center pt-3">
          <div className="col-lg-8 col-md-10 col-12">
            <div className="title-sec mb-3 text-center wow fadeInUp">
              <h2>Generate your track. Make your masterpiece</h2>
              <p>
                The Rolling Out AI Music Lab will quickly create a unique track for you. We have millions of samples;
                all you need to do is tell us the key, instruments, genre, beat, mood or vibe you want to achieve with
                your music. Our AI will get to work, and in a second, you’ll have an AI-generated beat or full track.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-xl-3 col-lg-4 col-md-4 col-12 order-2 order-sm-1">
            <div className="ai-music-genrator-main wow fadeInLeft">
              <form onSubmit={handleSubmit} className="ai-music-genrator-form instruments-list">
                <div className="basic-iput-data">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header select-genre-title" id="panelsStayOpen-headingOne">
                        <button
                          className={`accordion-button ${fields.genre ? "active-title" : ""}`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOne"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          Get Started
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          <input
                            onChange={(e) => setSearch({ ...search, genre: e.target.value })}
                            type="text"
                            className="form-control w-100 mb-3 search-input"
                            placeholder="Search genre..."
                          />
                          <div className="ai-music-genrator-form instruments-list select-genre">
                            {search.genre ? (
                              showGenreList
                            ) : (
                              <>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-check-input"
                                      onChange={handleChange}
                                      type="radio"
                                      name="genre"
                                      value="Electronic"
                                      id="flexCheckDefault1"
                                      checked={fields.genre === "Electronic"}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault1">
                                      Electronic
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-check-input"
                                      onChange={handleChange}
                                      type="radio"
                                      name="genre"
                                      value="Jazz"
                                      id="flexCheckChecked2"
                                      checked={fields.genre === "Jazz"}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked2">
                                      Jazz
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-check-input"
                                      onChange={handleChange}
                                      type="radio"
                                      name="genre"
                                      value="Afrobeats"
                                      id="flexCheckDefault3"
                                      checked={fields.genre === "Afrobeats"}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault3">
                                      Afrobeats
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-check-input"
                                      onChange={handleChange}
                                      type="radio"
                                      name="genre"
                                      value="Trap"
                                      id="flexCheckChecked4"
                                      checked={fields.genre === "Trap"}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked4">
                                      Trap
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-check-input"
                                      onChange={handleChange}
                                      type="radio"
                                      name="genre"
                                      value="Hip-Hop"
                                      id="flexCheckDefault5"
                                      checked={fields.genre === "Hip-Hop"}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault5">
                                      Hip-Hop
                                    </label>
                                  </div>
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-check-input"
                                      onChange={handleChange}
                                      type="radio"
                                      name="genre"
                                      value="Deep House"
                                      id="flexCheckChecked6"
                                      checked={fields.genre === "Deep House"}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked6">
                                      Deep House
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header select-energy-title" id="panelsStayOpen-headingOneenergy">
                        <button
                          className={`accordion-button ${fields.energy ? "active-title" : ""}`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseOneenergy"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseOneenergy"
                        >
                          Energy
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseOneenergy"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingOneenergy"
                      >
                        <div className="accordion-body">
                          <div className="ai-music-genrator-form select-energy">
                            <div className="form-check">
                              <div className="d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  onChange={handleChange}
                                  type="radio"
                                  name="energy"
                                  value="low"
                                  id="energy1"
                                  checked={fields.energy === "low"}
                                />
                                <label className="form-check-label" htmlFor="energy1">
                                  Low
                                </label>
                              </div>
                            </div>
                            <div className="form-check">
                              <div className="d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  onChange={handleChange}
                                  type="radio"
                                  name="energy"
                                  value="original"
                                  id="energy2"
                                  checked={fields.energy === "original"}
                                />
                                <label className="form-check-label" htmlFor="energy2">
                                  Original
                                </label>
                              </div>
                            </div>
                            <div className="form-check">
                              <div className="d-flex align-items-center">
                                <input
                                  className="form-check-input"
                                  onChange={handleChange}
                                  type="radio"
                                  name="energy"
                                  value="high"
                                  id="energy3"
                                  checked={fields.energy === "high"}
                                />
                                <label className="form-check-label" htmlFor="energy3">
                                  High
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header select-instruments-title" id="panelsStayOpen-headingTwo">
                        <button
                          className={`accordion-button ${fields.instruments.length > 0 ? "active-title" : ""}`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseTwo"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseTwo"
                        >
                          Instruments
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseTwo"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingTwo"
                      >
                        <div className="accordion-body">
                          <input
                            onChange={(e) => setSearch({ ...search, instrument: e.target.value })}
                            type="text"
                            className="form-control w-100 mb-3 search-input"
                            placeholder="Search instrument..."
                          />
                          <div className="ai-music-genrator-form instruments-list select-instruments">
                            {search.instrument ? (
                              showInstrumentList
                            ) : (
                              <>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "piano")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="piano"
                                      id="flexCheckDefault1"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault1">
                                      Piano
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "piano")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>

                                <input
                                  type="text"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "piano").length}
                                />
                                <span onClick={() => handleChangeInstrument("add", "piano")} className="plus bg-dark">
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "keyboard")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="keyboard"
                                      id="flexCheckChecked2"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked2">
                                      Keyboard
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "keyboard")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "keyboard").length}
                                />
                                <span
                                  onClick={() => handleChangeInstrument("add", "keyboard")}
                                  className="plus bg-dark"
                                >
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "acoustic guitar")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="acoustic guitar"
                                      id="flexCheckDefault3"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault3">
                                      Acoustic Guitar
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "acoustic guitar")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "acoustic guitar").length}
                                />
                                <span
                                  onClick={() => handleChangeInstrument("add", "acoustic guitar")}
                                  className="plus bg-dark"
                                >
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "electric guitar")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="electric guitar"
                                      id="flexCheckChecked4"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked4">
                                      Electric Guitar
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "electric guitar")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "electric guitar").length}
                                />
                                <span
                                  onClick={() => handleChangeInstrument("add", "electric guitar")}
                                  className="plus bg-dark"
                                >
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "bell")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="bell"
                                      id="flexCheckDefault5"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault5">
                                      Bell
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "bell")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "bell").length}
                                />
                                <span onClick={() => handleChangeInstrument("add", "bell")} className="plus bg-dark">
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "mallet")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="mallet"
                                      id="flexCheckChecked6"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked6">
                                      Mallet
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "mallet")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "mallet").length}
                                />
                                <span onClick={() => handleChangeInstrument("add", "mallet")} className="plus bg-dark">
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "drums")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="drums"
                                      id="flexCheckDefault7"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault7">
                                      Drums
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "drums")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "drums").length}
                                />
                                <span onClick={() => handleChangeInstrument("add", "drums")} className="plus bg-dark">
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "strings")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="strings"
                                      id="flexCheckChecked8"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked8">
                                      Strings
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "strings")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "strings").length}
                                />
                                <span onClick={() => handleChangeInstrument("add", "strings")} className="plus bg-dark">
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "voice")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="voice"
                                      id="flexCheckDefault9"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault9">
                                      Voice
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "voice")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "voice").length}
                                />
                                <span onClick={() => handleChangeInstrument("add", "voice")} className="plus bg-dark">
                                  +
                                </span>
                              </div> */}
                                </div>
                                <div className="form-check">
                                  <div className="d-flex align-items-center">
                                    <input
                                      onChange={handleInstrument}
                                      checked={fields.instruments.some((item) => item === "brass")}
                                      className="form-check-input"
                                      type="checkbox"
                                      value="brass"
                                      id="flexCheckChecked10"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckChecked10">
                                      Brass
                                    </label>
                                  </div>
                                  {/* <div className="qty">
                                <span
                                  onClick={() => handleChangeInstrument("remove", "brass")}
                                  className="minus bg-dark"
                                >
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="count"
                                  name="qty"
                                  value={fields.instruments.filter((item) => item === "brass").length}
                                />
                                <span onClick={() => handleChangeInstrument("add", "brass")} className="plus bg-dark">
                                  +
                                </span>
                              </div> */}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item mt-3">
                      <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                        <button
                          className={`accordion-button ${+fields.bpm || +fields.duration ? "active-title" : ""}`}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#panelsStayOpen-collapseThree"
                          aria-expanded="true"
                          aria-controls="panelsStayOpen-collapseThree"
                        >
                          BPMs & Durations
                        </button>
                      </h2>
                      <div
                        id="panelsStayOpen-collapseThree"
                        className="accordion-collapse collapse show"
                        aria-labelledby="panelsStayOpen-headingThree"
                      >
                        <div className="accordion-body">
                          <div className="ai-music-genrator-form instruments-list">
                            <div className="bpm-main mb-3">
                              <h5>
                                BPMs
                                <div className="info-section dropend">
                                  <button
                                    type="button"
                                    className="dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="ti ti-info-circle"></i>
                                  </button>
                                  <ul className="solid-square dropdown-menu dropdown-menu-dark">
                                    <li>
                                      <i className="ti ti-square-rotated-filled"></i> Trap is 140
                                    </li>
                                    <li>
                                      <i className="ti ti-square-rotated-filled"></i> Hip-Hop is 85 – 95
                                    </li>
                                    <li>
                                      <i className="ti ti-square-rotated-filled"></i> Techo is 120 – 125
                                    </li>
                                    <li>
                                      <i className="ti ti-square-rotated-filled"></i> House is 115 - 130
                                    </li>
                                    <li>
                                      <i className="ti ti-square-rotated-filled"></i> Lofi is 60
                                    </li>
                                  </ul>
                                </div>
                              </h5>
                              {/* <!-- <div className="range-slider">
                                                  <div id="tooltip"></div>
                                                  <input id="range" type="range" step="10" value="200" min="0" max="800"/>
                                                </div> --> */}
                              <div className="range-slider-area ">
                                <div className="range-slider">
                                  <span className="rs-label rs-bullet">{fields.bpm}</span>
                                  <input
                                    onChange={handleChange}
                                    name="bpm"
                                    className="rs-range rs-range-line"
                                    type="range"
                                    value={fields.bpm}
                                    min="0"
                                    max="140"
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <!-- <div className="form-floating mb-3">
                                              <select className="form-select" id="floatingSelect1" aria-label="Floating label select example">
                                                <option selected>Select Tempo</option>
                                                <option value="1">85</option>
                                                <option value="2">90</option>
                                                <option value="3">95</option>
                                                <option value="4">115</option>
                                                <option value="5">120</option>
                                                <option value="6">125</option>
                                                <option value="7">128</option>
                                                <option value="8">130</option>
                                                <option value="9">132</option>
                                                <option value="10">140</option>
                                                <option value="11">150</option>
                                              </select>
                                              <label htmlFor="floatingSelect1">Tempo</label>
                                            </div> --> */}
                            <div className="bpm-main mb-3">
                              <h5>Durations</h5>

                              <div className="range-slider-area" id="op-range-02">
                                <div className="range-slider">
                                  <span className="rs-label rs-bullet">{fields.duration}</span>
                                  <input
                                    onChange={handleChange}
                                    name="duration"
                                    className="rs-range rs-range-line"
                                    type="range"
                                    value={fields.duration}
                                    min="0"
                                    max="500"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {USER_TOKEN ? (
                    <button type="submit" className="btn-red mt-4 w-100 wow slideInUp" id="btn-ganerate-music">
                      Generate
                    </button>
                  ) : (
                    <Link to={"/signup"} className="btn-red mt-4 w-100 wow slideInUp">
                      Sign-Up and Generate
                    </Link>
                  )}
                </div>
              </form>
            </div>

            {audioList && (
              <div className="row mt-5">
                <div className="col-md-12 col-12">
                  <div className="ganerator-song-main mobile-view">
                    {/* <>
                      <div className="ganerator-song-title">
                        <div className="song-images">
                          <img src="assets/images/resources/ganerated-music.jpg" className="img-fluid" />
                        </div>
                        <div className="song-details">
                          {fields.genre ? <h3>{fields.genre}</h3> : ""}
                          <div className="selected-genr-list">
                            {fields.genre ? <span className="text-capitalize">{fields.genre}</span> : ""}
                            {fields?.instruments.length > 0 &&
                              fields?.instruments.map((instrument, i) => {
                                return (
                                  <span className="text-capitalize" key={i}>
                                    {instrument}
                                  </span>
                                );
                              })}
                            {fields.bpm ? <span>BPM : {fields.bpm}</span> : ""}{" "}
                            {fields.duration ? <span>Durations : {fields.duration}s</span> : ""}
                          </div>
                        </div>
                      </div>
                      <div className="ganerated-music-body">{showAudioList}</div>
                    </> */}
                    <AudioList fields={fields} showAudioList={showAudioList} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-xl-9 col-lg-8 col-md-8 col-12 order-1  order-sm-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="playlist-col wow fadeInRight">
                  <div className="music-creator-icon">
                    <i className="ti ti-adjustments-alt"></i>
                  </div>
                  <div className="playlist-info">
                    <h3>
                      <a href="#" title="">
                        Build Your Song
                      </a>
                    </h3>
                    <p>Select genre and other options to make your music unique.s</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="playlist-col wow fadeInRight" data-wow-delay="200ms">
                  <div className="music-creator-icon">
                    <i className="ti ti-circle-plus"></i>
                  </div>
                  <div className="playlist-info">
                    <h3>
                      <a href="#" title="">
                        Generate Tracks
                      </a>
                    </h3>
                    <p>Get new music tracks fast and listen to your creations.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="playlist-col wow fadeInRight" data-wow-delay="400ms">
                  <div className="music-creator-icon">
                    <i className="ti ti-download"></i>
                  </div>
                  <div className="playlist-info">
                    <h3>
                      <a href="#" title="">
                        Save & Download
                      </a>
                    </h3>
                    <p>Select the ones you like, add to your library or download.</p>
                  </div>
                </div>
              </div>
            </div>

            <div id="generated-music-container">
                {!audioList && (
                  <div className="row mt-5">
                    <div className="col-md-12 col-12">
                      <div className="ganerated-music-null">
                        <img src="assets/images/resources/music-headphone.png" className="img-fluid" alt="headphone" />
                      </div>
                    </div>
                  </div>
                )}

                {audioList && (
                  <div className="row mt-5">
                    <div className="col-md-12 col-12">
                      <div className="ganerator-song-main">
                        <AudioList fields={fields} showAudioList={showAudioList} />
                        {/* {showGenerateMusicResponse} */}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MusicGenerator;
